.register_div {
    height: calc( 100% );
  }
  
.register_div {
    display: flex;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
    /* background-color: #f5f5f5; */
    background-color: white;
    justify-content: center;
  }
  
.custom_min_width300 {
    min-width: 50vw;
  }
  
.form-signin {
    min-width: 240px;
    width: 50vw;
    max-width: 400px;
    padding: 15px;
  }
  
.form-signin .form-floating:focus-within {
    z-index: 2;
  }
  
.signup_logo {
  width: 96px;
}